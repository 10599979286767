import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo-words.svg";
import { useTheme } from "../../../context/ThemeContext";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "olivs-api-real-ip": "31.42.0.186",
  "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
  "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
  "api-key": "OlivsAuth",
};

const RegisterSuccessPanel = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [updatingEmail, setUpdatingEmail] = useState(false);
  const [login, setLogin] = useState("");
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [loginSecured, setLoginSecured] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailResent, setEmailResent] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);

  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (
      (!location.state?.firstName || !location.state?.email) &&
      (!login || !email)
    ) {
      navigate("/register");
    } else {
      if (!login || !email) {
        setLogin(location.state?.firstName);
        setEmail(location.state?.email);
        setLoginSecured(location.state?.loginSecured);
      }
    }
  }, [location.state]);

  const handleResendEmail = async () => {
    if (loadingResend) return;
    setLoadingResend(true);
    const data = JSON.stringify({
      LoginSecuredHash: loginSecured,
    });

    try {
      const response = await fetch(
        "https://dev1.olivs.com/api/v3/en-au/users/send-verification-email?basehosturl=login.olivs.com",
        {
          method: "POST",
          headers: headers,
          body: data,
        }
      );

      const responseData = await response.json();
      console.log("Resend Email Response:", responseData);
      if (responseData.EmailSentYN === "Y") {
        setEmailResent(true);
      }
      setLoadingResend(false);
    } catch (error) {
      setLoadingResend(false);
      console.error("Error:", error);
    }
  };

  const handleUpdateEmail = async () => {
    setErrorMessage("");
    const url =
      "https://dev1.olivs.com/api/v3/en-au/users/change-login-email?BaseHostURL=login.olivs.com";

    const data = JSON.stringify({
      LoginSecuredHash: loginSecured,
      NewLoginEmail: newEmail,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: data,
      });

      const responseData = await response.json();
      console.log("Response:", responseData);
      if (responseData.SuccessYN === "Y") {
        setUpdatingEmail(false);
        setEmail(newEmail);
        setLoginSecured("");
      } else {
        setErrorMessage(responseData.ErrorMessage);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
    }
  };

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <Logo height={38} style={{ position: "absolute", top: "64px" }} />
      <Box sx={{ mt: "150px" }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: isDarkMode ? "#7D8CAA" : "#495670",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "133.4%",
          }}
        >
          Create Your Account
        </Typography>

        <Box
          sx={{
            mt: isMobile ? "20px" : "100px",
            borderBottom: "1px solid #EDEDED",
            paddingBottom: "39px",
            width: isMobile ? "100%" : isTablet ? 380 : 480,
          }}
        >
          <Typography sx={{ mb: 3 }}>Welcome, {login}!</Typography>
          <Typography sx={{ mb: 3 }}>
            For security purposes, we must now verify your e-mail address and
            phone number.
          </Typography>
          <Typography>
            Please check your inbox ({email}) and click on the link in an e-mail
            you recevied from us.
          </Typography>
          {!loginSecured ? (
            <></>
          ) : !updatingEmail ? (
            <Box sx={{ mb: 1, mt: 1 }}>
              <Link
                className="link"
                onClick={() => setUpdatingEmail(true)}
                style={{
                  color: isDarkMode ? "#AAD269" : "#849F23",
                  marginTop: 0,
                  cursor: "pointer",
                  fontSize: "14px",
                  fontWeight: 700,
                  letterSpacing: "0.4px",
                  textTransform: "capitalize",
                  textDecoration: "none",
                }}
              >
                Edit E-mail Address
              </Link>
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyItems: "center" }}>
              <TextField
                variant="outlined"
                margin="normal"
                label="Email Address"
                inputProps={{
                  autoComplete: "off",
                }}
                required
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                sx={{
                  width: 240,
                  mt: 0,
                  mb: 0,
                  label: { fontSize: 16, marginTop: 0 },
                }}
              />
              <Button
                onClick={handleUpdateEmail}
                sx={{ height: "40px" }}
                variant="secondary"
              >
                Update
              </Button>
            </Box>
          )}
          {errorMessage && (
            <Alert severity="warning" sx={{ mt: 1 }}>
              {errorMessage}
            </Alert>
          )}
          <Typography sx={{ mb: 4 }}>
            Didn't get the email? Check your spam folder. Found it in spam? Add
            support@olivs.com to safe list.
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 2,
            mb: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {emailResent ? (
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Please check your email shortly.
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                You didn’t get the e-mail?
              </Typography>
              <Link
                onClick={handleResendEmail}
                className="link"
                style={{
                  color: loadingResend ? "gray" : "#849F23",
                  marginLeft: 10,
                  marginTop: 0,
                  cursor: loadingResend ? "default" : "pointer",
                  fontWeight: 700,
                  fontSize: 14,
                  letterSpacing: "0.4px",
                  textTransform: "capitalize",
                  textDecoration: loadingResend ? "none !imporant" : "none",
                }}
              >
                Resend the E-mail
              </Link>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterSuccessPanel;
