import md5 from "md5";

export const getLoginSecured = (loginEmail, loginPassword) => {
  const hashedPassword = md5(loginPassword);
  const combinedString = `${loginEmail}:${hashedPassword}`;
  const base64Encoded = btoa(combinedString);
  return base64Encoded;
};

export const LOGIN_API_OLIVS_APP_URL = "https://login-api.olivs.com/api/v1/";
