import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo-words.svg";
import { useTheme } from "../../../context/ThemeContext";
import { LOGIN_API_OLIVS_APP_URL } from "../../../utils/common";

const VerifyPhone = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
  const [phoneNumberSaved, setPhoneNumberSaved] = useState("");
  const [code, setCode] = useState();
  const [sessionId, setSessionId] = useState("");
  const [loginSecuredHash, setLoginSecuredHash] = useState();
  const [apiKey, setApiKey] = useState();
  const [ip, setIp] = useState();

  const [updatingPhone, setUpdatingPhone] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseSuccess = () => {
    setOpenSuccess(false);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!mobilePhoneNumber || mobilePhoneNumber?.length < 5) {
      setErrorMessage("Invalid phone number.");
      setOpen(true);
      return;
    }
    if (!mobilePhoneNumber?.startsWith("+61")) {
      setErrorMessage("Mobile phone number needs to start with +61");
      setOpen(true);
      return;
    }

    try {
      const response = await fetch(
        `https://dev1.olivs.com/api/v3/en-au/users/change-phone-number?BaseHostURL=login.olivs.com`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": "OlivsAuth",
          },
          body: JSON.stringify({
            LoginSecuredHash: loginSecuredHash,
            newMobilePhoneNumber: mobilePhoneNumber,
          }),
        }
      );
      const data = await response.json();

      if (data.SuccessYN === "N") {
        setErrorMessage(
          data.ErrorMessage || "Something went wrong. Please try again"
        );
        setOpen(true);
      } else if (data.SuccessYN === "Y") {
        setPhoneNumberSaved(mobilePhoneNumber);
        setUpdatingPhone(false);
        setSuccessMessage("You phone number has been updated.");
        setOpenSuccess(true);
        setMobilePhoneNumber("");
      }
    } catch (error) {
      console.error("An error occurred while updating:", error);
      setErrorMessage("Something went wrong. Please try again");
      setOpen(true);
    }
  };

  useEffect(() => {
    if (location.state?.phone && location.state?.apiKey && !phoneNumberSaved) {
      setPhoneNumberSaved(location.state.phone);
      setLoginSecuredHash(location.state.loginSecuredHash);
      setSessionId(location.state.sessionId);
      setApiKey(location.state.apiKey);
    } else if (!location?.state?.phone && !phoneNumberSaved) {
      navigate("/");
    }
  }, [location.state]);

  const handleSubmit = async () => {
    if (!code || code?.length < 5) {
      setErrorMessage("Invalid verification code.");
      setOpen(true);
      return;
    }
    try {
      const verificationUrl =
        LOGIN_API_OLIVS_APP_URL +
        "en-au/system/validate-2fa-code?BaseHostURL=login.olivs.com";

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "olivs-api-real-ip": "115.70.37.103",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "api-key": apiKey,
      };

      const verificationData = JSON.stringify({
        UserLogin2FACode: parseInt(code),
      });

      const verificationResponse = await fetch(verificationUrl, {
        method: "POST",
        headers: headers,
        body: verificationData,
      });

      const verificationJson = await verificationResponse.json();
      if (verificationJson.Valid2FACodeYN === "Y") {
        const approveUrl =
          "https://dev1.olivs.com/api/v3/en-au/users/approve-phone-number?BaseHostURL=login.olivs.com";

        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "olivs-api-real-ip": "115.70.37.103",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": "OlivsAuth",
        };

        const approveResponse = await fetch(approveUrl, {
          method: "POST",
          headers: headers,
          body: JSON.stringify({
            LoginSecuredHash: loginSecuredHash,
          }),
        });

        const approveJson = await approveResponse.json();
        if (approveJson?.SuccessYN === "Y") {
          setOpenSuccess(true);
          setSuccessMessage(
            `Your phone number has been verified. You will soon be redirected`
          );
          await verifyAll();
        } else {
          setErrorMessage(approveJson?.ErrorMessage);
          setOpen(true);
        }
      }
    } catch {
      setErrorMessage(
        "Error occurred while processing the code. Please try again."
      );
      setOpen(true);
    }
  };

  const verifyAll = async () => {
    try {
      const allUrl =
        "https://dev1.olivs.com/api/v3/en-au/users/activate-created?BaseHostURL=login.olivs.com";

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "olivs-api-real-ip": "115.70.37.103",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "api-key": "OlivsAuth",
      };

      const allResponse = await fetch(allUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          LoginSecuredHash: loginSecuredHash,
        }),
      });

      const allJson = await allResponse.json();
      if (allJson?.SuccesYN === "Y" || allJson?.SuccessYN === "Y") {
        await newSession();
      } else {
        setErrorMessage(allJson?.ErrorMessage);
        setOpen(true);
      }
    } catch {
      setErrorMessage(
        "Error occurred while approving the user. Please try again."
      );
      setOpen(true);
    }
  };

  const newSession = async () => {
    const newSessionApiUrl =
      LOGIN_API_OLIVS_APP_URL +
      "en-au/session-management/create-new-session-instance?BaseHostURL=login.olivs.com";

    const sessionHeaders = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "olivs-api-real-ip": "115.70.37.103",
      "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
      "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
      "browser-agent": window.navigator.userAgent,
      "session-id": sessionId,
      "api-key": apiKey,
    };

    const initialTokenData = JSON.stringify({
      LoginSecuredHash: loginSecuredHash,
      DatabaseId: 0,
      CurrentHost: "https://login.olivs.com",
      LoginPageUrl: "https://login.olivs.com",
      RedirectPageUrl: "https://s8.olivs.com",
      IpAddress: ip,
      UserAgentAkaBrowserDetails: navigator.userAgent,
      ReferrerUrl: "https://login.olivs.com/",
    });

    const sessionManagementResponse = await fetch(newSessionApiUrl, {
      method: "POST",
      headers: sessionHeaders,
      body: initialTokenData,
    });

    const sessionManagementJson = await sessionManagementResponse.json();
    if (sessionManagementJson?.successYN === "Y") {
      window.location = sessionManagementJson?.redirectPageUrl;
    } else {
      setErrorMessage(sessionManagementJson?.errorMessage);
      setOpen(true);
    }
  };

  const resendCode = async () => {
    try {
      const smsUrl =
        "https://dev1.olivs.com/api/v3/en-au/user2fa/send-code-as-sms?BaseHostURL=login.olivs.com";

      const headers = {
        Accept: "application/json",
        "olivs-api-real-ip": "115.70.37.103",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "api-key": apiKey,
      };

      const smsResponse = await fetch(smsUrl, {
        method: "POST",
        headers: headers,
      });

      const smsJson = await smsResponse.json();
      if (smsJson?.SmsSentYN === "Y") {
        setSuccessMessage("SMS has been sent.");
        setOpenSuccess(true);
      } else {
        setErrorMessage(smsJson?.ErrorMessage);
        setOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        "Error occurred while sending the code. Please try again."
      );
      setOpen(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const responseIp = await fetch("https://api.ipify.org?format=json");
      const dataIp = await responseIp.json();
      setIp(dataIp?.ip);
    };
    if (!ip) fetchData();
  }, []);

  return (
    <Box
      sx={{
        // position: "relative",
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <Snackbar
        sx={{
          zIndex: "9999",
          position: "absolute",
          bottom: open && openSuccess ? "80px !important" : "unset",
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{
          zIndex: "9999",
          position: "absolute",
          bottom: open && openSuccess ? "24px !important" : "unset",
        }}
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
      <Logo height={38} style={{ position: "absolute", top: "64px" }} />
      <Box sx={{ mt: "150px" }}>
        <form>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              color: isDarkMode ? "#7D8CAA" : "#495670",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "Inter",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "133.4%",
            }}
          >
            Create Your Account
          </Typography>
          <Box
            sx={{
              mt: isMobile
                ? "20px"
                : isTablet
                ? "30px"
                : noFullFit
                ? "70px"
                : "100px",
              borderBottom: "1px solid #EDEDED",
              paddingBottom: "39px",
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          >
            <Typography sx={{ mb: 3 }}>
              Your e-mail address is verified!
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Now, let’s verify your phone number: we’ve sent a text message
              (SMS) with verification code to your phone
              {` (${phoneNumberSaved})`}. Please enter it below.
            </Typography>
            {!updatingPhone ? (
              <Box sx={{ mb: 1 }}>
                <Link
                  className="link"
                  onClick={() => setUpdatingPhone(true)}
                  style={{
                    color: isDarkMode ? "#AAD269" : "#849F23",
                    marginTop: 0,
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 700,
                    letterSpacing: "0.4px",
                    textTransform: "capitalize",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Edit Phone Number
                </Link>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyItems: "center" }}>
                <TextField
                  name="mobilePhoneNumber"
                  variant="outlined"
                  value={mobilePhoneNumber}
                  onChange={(e) => setMobilePhoneNumber(e.target.value)}
                  margin="normal"
                  label="Mobile Phone Number"
                  required
                  sx={{
                    width: 240,
                    mt: 0,
                    mb: 0,
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                />
                <Button
                  sx={{ height: "40px" }}
                  variant="secondary"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </Box>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              label="Verification Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              sx={{
                width: isMobile ? "100%" : isTablet ? 380 : 480,
                mb: 0,
                label: { fontSize: 16, marginTop: 0 },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{
                mt: 6,
                mb: 0,
                width: isMobile ? "100%" : isTablet ? 380 : 480,
              }}
            >
              Sign Up
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              mb: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              SMS didn't arrive?
            </Typography>
            <Link
              onClick={resendCode}
              className="link"
              style={{
                color: isDarkMode ? "#AAD269" : "#849F23",
                marginLeft: 10,
                marginTop: 0,
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 700,
                letterSpacing: "0.4px",
                textTransform: "capitalize",
                textDecoration: "none",
              }}
            >
              Resend the SMS
            </Link>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default VerifyPhone;
