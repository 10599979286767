import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo-words.svg";
import { useState, useEffect } from "react";
import { useRef } from "react";
import ThemeToggle from "../../ThemeToggle/ThemeToggle";
import { useTheme } from "../../../context/ThemeContext";
import { LOGIN_API_OLIVS_APP_URL } from "../../../utils/common";

const TwoFAPanel = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTallScreen, setIsTallScreen] = useState(window.innerHeight > 1015);
  const [userEmail, setUserEmail] = useState();
  const [apiKey, setApiKey] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [ip, setIp] = useState("");
  const [loginSecuredHash, setLoginSecuredHash] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smsSent, setSmsSent] = useState(false);

  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const inputRefs = [
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ];

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;

    if (value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }

    setCode(newCode);
  };

  const handleBackspace = (index) => {
    if (!code[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }

    const newCode = [...code];
    newCode[index] = "";

    setCode(newCode);
  };

  const handleVerification = async () => {
    setLoading(true);
    const isInputValid = code.every((digit) => digit !== "");

    if (isInputValid) {
      const verificationCode = code.join("");
      try {
        const verificationUrl =
          LOGIN_API_OLIVS_APP_URL +
          "en-au/system/validate-2fa-code?BaseHostURL=login.olivs.com";

        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "115.70.37.103",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "session-id": sessionId,
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": apiKey,
        };

        const verificationData = JSON.stringify({
          UserLogin2FACode: parseInt(verificationCode),
          RememberMeYN: rememberMe ? "Y" : "N",
        });

        const verificationResponse = await fetch(verificationUrl, {
          method: "POST",
          headers: headers,
          body: verificationData,
        });

        const verificationJson = await verificationResponse.json();
        if (verificationJson.SuccessYN === "Y") {
          const newSessionApiUrl =
            LOGIN_API_OLIVS_APP_URL +
            "en-au/session-management/create-new-session-instance?BaseHostURL=login.olivs.com";

          const sessionHeaders = {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "browser-agent": window.navigator.userAgent,
            "session-id": sessionId,
            "api-key": apiKey,
          };

          const initialTokenData = JSON.stringify({
            LoginSecuredHash: loginSecuredHash,
            DatabaseId: 0,
            CurrentHost: "https://login.olivs.com",
            LoginPageUrl: "https://login.olivs.com",
            RedirectPageUrl: "https://s8.olivs.com",
            IpAddress: ip,
            UserAgentAkaBrowserDetails: navigator.userAgent,
            ReferrerUrl: "https://login.olivs.com/",
          });

          const sessionManagementResponse = await fetch(newSessionApiUrl, {
            method: "POST",
            headers: sessionHeaders,
            body: initialTokenData,
          });

          const sessionManagementJson = await sessionManagementResponse.json();
          if (sessionManagementJson?.successYN === "Y") {
            window.location = sessionManagementJson?.redirectPageUrl;
          } else {
            setLoading(false);
            setErrorMessage(sessionManagementJson?.errorMessage);
            setOpen(true);
          }
        } else {
          setLoading(false);
          setErrorMessage(verificationJson.ErrorMessage);
          setOpen(true);
        }
      } catch (error) {
        setLoading(false);
        setErrorMessage(
          "Error occurred while processing the code. Please try again."
        );
        setOpen(true);
      }
    } else {
      setLoading(false);
      // empty input
    }
  };

  const sendCodeViaSMS = async () => {
    try {
      const smsUrl =
        "https://dev1.olivs.com/api/v3/en-au/user2fa/send-code-as-sms?BaseHostURL=login.olivs.com";

      const headers = {
        Accept: "application/json",
        "olivs-api-real-ip": "115.70.37.103",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "api-key": apiKey,
      };

      const smsResponse = await fetch(smsUrl, {
        method: "POST",
        headers: headers,
      });

      const smsJson = await smsResponse.json();
      if (smsJson?.SmsSentYN === "Y") {
        setSmsSent(true);
        setOpenSuccess(true);
      } else {
        setErrorMessage(smsJson?.ErrorMessage);
        setOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        "Error occurred while sending the code. Please try again."
      );
      setOpen(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsTallScreen(window.innerHeight > 1015);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    inputRefs[0].current.focus();
  }, []);

  useEffect(() => {
    if (
      location.state?.email &&
      location.state?.apiKey &&
      location.state?.ip &&
      !userEmail
    ) {
      setUserEmail(location.state.email);
      setApiKey(location.state.apiKey);
      setIp(location.state.ip);
      setSessionId(location.state.sessionId);
      setLoginSecuredHash(location.state.loginSecuredHash);
    } else if (!location?.state?.email && !userEmail) {
      navigate("/");
    }
  }, [location.state]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "r") {
        setRememberMe((prevRememberMe) => !prevRememberMe);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <Snackbar
        sx={{ zIndex: "9999" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage || "Something went wrong. Please try again"}
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{ zIndex: "9999" }}
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="info"
          variant="filled"
          sx={{ width: "100%" }}
        >
          The code has been sent via SMS
        </Alert>
      </Snackbar>
      <Logo height={38} style={{ marginTop: "64px" }} />
      <Box sx={{ mt: isTallScreen ? "188px" : "18%" }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: isDarkMode ? "#7D8CAA" : "#495670",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "133.4%",
          }}
        >
          Two-factor authentication
        </Typography>

        <Box
          sx={{
            mt: "21px",
            borderBottom: "1px solid #EDEDED",
            paddingBottom: "39px",
            width: isMobile ? "100%" : isTablet ? 380 : 480,
          }}
        >
          <Typography>
            Enter the code from your authentication app for {userEmail}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              mb: "43px",
            }}
          >
            {code.map((digit, index) => (
              <TextField
                key={index}
                type="text"
                variant="outlined"
                margin="normal"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Backspace") {
                    handleBackspace(index);
                  } else if (index > 4 && e.key === "Enter") {
                    handleVerification();
                  }
                }}
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: "center", fontSize: "16px" },
                }}
                sx={{ width: "40px", input: { minHeight: "39px" }, mr: "4px" }}
                inputRef={inputRefs[index]}
              />
            ))}
          </Box>
          {!smsSent && (
            <Link
              className="link"
              onClick={sendCodeViaSMS}
              style={{
                color: isDarkMode ? "#AAD269" : "#849F23",
                marginTop: 0,
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 700,
                letterSpacing: "0.4px",
                textTransform: "capitalize",
                textDecoration: "none",
              }}
            >
              Send code via SMS
            </Link>
          )}
          <Box>
            <FormControlLabel
              sx={{ mt: 1, span: { fontSize: 14 } }}
              control={
                <Checkbox
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={() =>
                    setRememberMe((prevRememberMe) => !prevRememberMe)
                  }
                />
              }
              label={
                <span>
                  <u>R</u>emember Me
                </span>
              }
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || !code.every((digit) => digit !== "")}
            onClick={handleVerification}
            sx={{ mt: 3, mb: 0, width: isMobile ? "100%" : 480 }}
          >
            Login
          </Button>
        </Box>
        <Box
          sx={{
            mt: 2,
            mb: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isMobile ? "100%" : isTablet ? 380 : 480,
          }}
        >
          <Link
            className="link"
            to={"/login"}
            style={{
              color: isDarkMode ? "#AAD269" : "#849F23",
              marginTop: 0,
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
          >
            Log out
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ThemeToggle
          titlefontSize={16}
          title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        />
      </Box>
    </Box>
  );
};

export default TwoFAPanel;
