import React, { useEffect, useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import { LOGIN_API_OLIVS_APP_URL } from "../../../utils/common";

const Logout = () => {
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const handleLogout = async () => {
      try {
        const params = new URLSearchParams(location.search);
        const loginSecuredHash = params.get("loginSecuredHash");

        if (!loginSecuredHash) {
          throw new Error("Missing URL parameter.");
        }

        const response = await fetch(
          LOGIN_API_OLIVS_APP_URL +
            "en-au/system/destroy-api-token-details?BaseHostURL=login.olivs.com",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "olivs-api-real-ip": "115.70.37.103",
              "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
              "browser-agent": window.navigator.userAgent,
              "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
              "api-key": "OlivsAuth",
            },
            body: JSON.stringify({
              LoginSecuredHash: loginSecuredHash,
            }),
          }
        );

        const data = await response.json();
        if (data?.SuccessYN === "Y") {
          document.cookie =
            "apiKey=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie =
            "loginSecuredHash=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          setSnackbarMessage("You have been logged out");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            window.location = "https://olivs.com";
          }, 1000);
        } else {
          throw new Error(data?.ErrorMessage || "Logout failed");
        }
      } catch (error) {
        setSnackbarMessage(error.message || "An error occurred");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
        setTimeout(() => {
          window.location = "https://olivs.com";
        }, 2000);
      }
    };

    handleLogout();
  }, [location.search]);

  return (
    <>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Logout;
