import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/logo-words.svg";
import { useTheme } from "../../../context/ThemeContext";
import ReCAPTCHA from "react-google-recaptcha";

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { isDarkMode } = useTheme();

  const [isTallScreen, setIsTallScreen] = useState(window.innerHeight > 1015);
  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    password: "",
    repeatPassword: "",
  });

  const [recoveryHash, setRecoveryHash] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const param = searchParams.get("recovery");
    if (param) {
      setRecoveryHash(param);
    } else {
      navigate("/");
    }
  }, []);
  if (!recoveryHash) {
    navigate("/");
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{9,}$/;
    if (!passwordRegex.test(formData.password)) {
      setAlertMessage(
        "Password must contain at least 9 characters, including 1 lowercase letter, 1 uppercase letter, and 1 special character."
      );
      setOpen(true);
      return;
    }

    if (formData.password !== formData.repeatPassword) {
      setAlertMessage("Passwords don't match");
      setOpen(true);
      return;
    }

    try {
      const response = await fetch(
        "https://dev1.olivs.com/api/v3/en-au/users/update-login-password?BaseHostURL=login.olivs.com",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": "OlivsAuth",
          },
          body: JSON.stringify({
            NewPasswordBase64: btoa(formData.password),
            ForgotPasswordHash: recoveryHash,
          }),
        }
      );
      const data = await response.json();

      if (data?.SuccessYN === "Y") {
        setSuccessMessage(
          "Password changed successfully. You will be redirected soon"
        );
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else {
        setAlertMessage(data.ErrorMessage || "An error occurred");
        setOpen(true);
      }
    } catch (error) {
      setAlertMessage("An error occurred");
      setOpen(true);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsTallScreen(window.innerHeight > 1015);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      <Logo height={38} style={{ position: "absolute", top: "64px" }} />
      <Box sx={{ mt: isTallScreen ? "150px" : "130px" }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: isDarkMode ? "#7D8CAA" : "#495670",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "133.4%",
          }}
        >
          Change Password
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <Box>
            <TextField
              sx={{
                mt: isTallScreen ? 4 : 2,
                mb: 0,
                width: isMobile ? "100%" : isTablet ? 380 : 480,
                label: { fontSize: 16, marginTop: 0 },
              }}
              fullWidth
              variant="outlined"
              margin="normal"
              label="New Password"
              type="password"
              required
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            <TextField
              sx={{
                mt: isTallScreen ? 4 : 2,
                mb: 0,
                width: isMobile ? "100%" : isTablet ? 380 : 480,
                label: { fontSize: 16, marginTop: 0 },
              }}
              fullWidth
              variant="outlined"
              margin="normal"
              label="Repeat New Password"
              type="password"
              required
              name="repeatPassword"
              value={formData.repeatPassword}
              onChange={handleChange}
            />
          </Box>

          <Button
            sx={{
              marginTop: isTallScreen ? 6 : 4,
              mb: 0,
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ChangePassword;
