import { ThemeProvider } from "./context/ThemeContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import { CssBaseline } from "@mui/material";
import Logout from "./components/Login/Logout/Logout";

const App = () => {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <>
          <CssBaseline />
          <Routes>
            <Route path="/logout" element={<Logout />}></Route>
            <Route
              path={`*`}
              element={
                <>
                  <Login />
                </>
              }
            />
          </Routes>
        </>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
