import React, { useState, useEffect } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import HelpTooltip from "../../HelpTooltip/HelpTooltip";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as Logo } from "../../../assets/logo-words.svg";
import { useTheme } from "../../../context/ThemeContext";
import { getLoginSecured } from "../../../utils/common";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  nickName: Yup.string(),
  mobileNumber: Yup.string().required("Mobile Phone Number is required"),
  phonePrefix: Yup.string()
    .required("Prefix is required")
    .test("phone-prefix", "Invalid prefix", (value) => {
      if (!value.startsWith("+")) {
        return false;
      }
      if (value.length < 3 || value.length > 5) {
        return false;
      }
      return true;
    }),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(9, "Password should be at least 9 characters")
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).{9,}$/,
      "Password should contain at least one lowercase, one uppercase, one digit, and one special character"
    ),
  repeatPassword: Yup.string()
    .required("Repeat Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "You must accept the Terms & Conditions"
  ),
});

const RegisterPanel = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [isTallScreen, setIsTallScreen] = useState(window.innerHeight > 1015);
  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const onSubmit = async (values, { setSubmitting }) => {
    setErrorMessage("");
    if (!recaptchaValue) return;
    const url =
      "https://dev1.olivs.com/api/v3/en-au/users/create-new?sendemailYN=Y&BaseHostURL=login.olivs.com";

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "olivs-api-real-ip": "31.42.0.186",
      "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
      "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
      "api-key": "OlivsAuth",
    };

    const data = JSON.stringify({
      FirstName: values.firstName,
      LastName: values.lastName,
      NickName: values.nickName,
      LoginEmail: values.email,
      MobilePhoneNo: values.phonePrefix + values.mobileNumber,
      Password: values.password,
      RepeatPassword: values.repeatPassword,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: data,
      });

      const responseData = await response.json();
      console.log("Response:", responseData);

      // Redirect or perform any other action on successful registration
      if (responseData.SuccessYN === "Y") {
        navigate("/verify-email", {
          state: {
            firstName: values.firstName,
            email: values.email,
            loginSecured: getLoginSecured(values.email, values.password),
          },
        });
      } else {
        setErrorMessage(responseData.ErrorMessage);
      }

      // Reset the form and loading state
      setSubmitting(false);
    } catch (error) {
      console.error("Error:", error);
      // Handle error here
      // You might want to set an error state or display a message to the user
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      nickName: "",
      mobileNumber: "",
      phonePrefix: "+61",
      email: "",
      password: "",
      repeatPassword: "",
      termsAndConditions: false,
    },
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    const handleResize = () => {
      setIsTallScreen(window.innerHeight > 1015);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <Logo height={38} style={{ position: "absolute", top: "64px" }} />
      <Box sx={{ mt: isTallScreen ? "150px" : "130px" }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: isDarkMode ? "#7D8CAA" : "#495670",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "133.4%",
          }}
        >
          Create Your Account
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: isTablet ? "column" : "row",
                mt: isTallScreen ? "43px" : "10px",
                mb: 0,
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                label="First Name"
                required
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={
                  formik.touched.firstName ? formik.errors.firstName : ""
                }
                sx={{
                  width: 240,
                  mb: 0,
                  mr: 2,
                  label: { fontSize: 16, marginTop: 0 },
                }}
              />

              {/* Last Name Field */}
              <TextField
                variant="outlined"
                margin="normal"
                label="Last Name"
                required
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={
                  formik.touched.lastName ? formik.errors.lastName : ""
                }
                sx={{
                  width: 240,
                  mb: 0,
                  mr: 2,
                  label: { fontSize: 16, marginTop: 0 },
                }}
              />
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Nick Name"
              name="nickName"
              value={formik.values.nickName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              sx={{
                mt: isTallScreen ? 4 : 2,
                mb: 0,
                width: 240,
                label: { fontSize: 16, marginTop: 0 },
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                name="phonePrefix"
                value={formik.values.phonePrefix}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.phonePrefix &&
                  Boolean(formik.errors.phonePrefix)
                }
                helperText={
                  formik.touched.phonePrefix ? formik.errors.phonePrefix : ""
                }
                sx={{
                  mt: isTallScreen ? 4 : 2,
                  mb: 0,
                  width: 64,
                  mr: 2,
                  label: { fontSize: 16, marginTop: 0 },
                  ".MuiFormHelperText-root.Mui-error": {
                    mx: 1,
                  },
                }}
              />

              {/* Mobile Phone Number Field */}
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                label="Mobile Phone Number"
                type="tel"
                required
                name="mobileNumber"
                value={formik.values.mobileNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.mobileNumber &&
                  Boolean(formik.errors.mobileNumber)
                }
                helperText={
                  formik.touched.mobileNumber ? formik.errors.mobileNumber : ""
                }
                sx={{
                  mt: isTallScreen ? 4 : 2,
                  mb: 0,
                  width: 240,
                  label: { fontSize: 16, marginTop: 0 },
                }}
              />
            </Box>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Email Address"
              inputProps={{
                autoComplete: "off",
              }}
              type="email"
              required
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email ? formik.errors.email : ""}
              sx={{
                mt: isTallScreen ? 4 : 2,
                mb: 0,
                width: 380,
                label: { fontSize: 16, marginTop: 0 },
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: isTallScreen ? 4 : 2,
              }}
            >
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                label="Password"
                type="password"
                required
                name="password"
                inputProps={{
                  autoComplete: "off",
                }}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={
                  formik.touched.password ? formik.errors.password : ""
                }
                sx={{
                  mt: 0,
                  mb: 0,
                  mr: "4px",
                  width: 348,
                  label: { fontSize: 16, marginTop: 0 },
                }}
              />
              <HelpTooltip
                tooltipContent={
                  <Box
                    sx={{
                      color: "#ffffff",
                      fontSize: "14px",
                      padding: "4px 8px",
                    }}
                  >
                    Your password should contain at least:
                    <ul
                      style={{
                        margin: 0,
                        padding: 0,
                        paddingLeft: "20px",
                      }}
                    >
                      <li>9 characters</li>
                      <li>1 lowercase</li>
                      <li>1 UPPERCASE</li>
                      <li>1 special character</li>
                    </ul>
                  </Box>
                }
              />
            </Box>
            <TextField
              variant="outlined"
              margin="normal"
              label="Repeat Password"
              type="password"
              required
              name="repeatPassword"
              value={formik.values.repeatPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.repeatPassword &&
                Boolean(formik.errors.repeatPassword)
              }
              helperText={
                formik.touched.repeatPassword
                  ? formik.errors.repeatPassword
                  : ""
              }
              sx={{
                mt: isTallScreen ? 4 : 2,
                mb: 0,
                width: 348,
                label: { fontSize: 16, marginTop: 0 },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="termsAndConditions"
                  checked={formik.values.termsAndConditions}
                  onChange={formik.handleChange}
                />
              }
              label={
                <div style={{ fontSize: 14 }}>
                  I agree to Olivs{" "}
                  <a
                    className="link"
                    href="https://olivs.com/terms-conditions"
                    target="blank"
                    style={{
                      color: isDarkMode ? "#AAD269" : "#849F23",
                      zIndex: 3333,
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 700,
                      letterSpacing: "0.4px",
                      textTransform: "capitalize",
                      textDecoration: "none",
                    }}
                  >
                    Terms & Conditions
                  </a>
                  {` and `}
                  <a
                    href="https://olivs.com/privacy-policy/"
                    className="link"
                    target="blank"
                    style={{
                      color: isDarkMode ? "#AAD269" : "#849F23",
                      zIndex: 3333,
                      cursor: "pointer",
                      fontSize: "14px",
                      fontWeight: 700,
                      letterSpacing: "0.4px",
                      textTransform: "capitalize",
                      textDecoration: "none",
                    }}
                  >
                    Privacy Policy
                  </a>
                  <span style={{ color: "#D32F2F" }}>*</span>
                </div>
              }
              sx={{ mt: isTallScreen ? 5 : 2, mb: "12px" }}
            />
          </Box>

          <ReCAPTCHA
            sitekey="6LdYx4IpAAAAAAaLdLwBuPqrQ6SpuFsrhYiFB_nD"
            // sitekey="6Ld3tWEpAAAAAFLHNAg3dWSEuv8rCyBNt6l6meJE"
            onChange={handleRecaptcha}
            theme={isDarkMode ? "dark" : "light"}
          />

          <Box
            sx={{
              width: isMobile ? "100%" : isTablet ? 380 : 480,
              borderBottom: "1px solid #EDEDED",
              paddingBottom: "39px",
            }}
          >
            {errorMessage && (
              <Alert severity="warning" sx={{ mb: 1 }}>
                {errorMessage}
              </Alert>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                marginTop: isTallScreen ? 6 : 4,
                mb: 0,
                width: isMobile ? "100%" : isTablet ? 380 : 480,
              }}
            >
              Sign Up
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              mb: isTallScreen ? 4 : 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          >
            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
              Already have an account?
            </Typography>
            <Link
              className="link"
              to={"/login"}
              style={{
                color: isDarkMode ? "#AAD269" : "#849F23",
                marginLeft: 10,
                marginTop: 0,
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 700,
                letterSpacing: "0.4px",
                textTransform: "capitalize",
                textDecoration: "none",
              }}
            >
              Login Now
            </Link>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default RegisterPanel;
