import { ReactComponent as FloatingMan } from "../../assets/floating_person_login.svg";
import React, { useEffect, useState } from "react";
import { Typography, Link, Box, useMediaQuery } from "@mui/material";
import { ReactComponent as Linkedin } from "../../assets/linkedin.svg";
import { ReactComponent as FloatingManBackground } from "../../assets/floating-person-background.svg";
import RegisterSuccessPanel from "../../components/Login/RegisterSuccess/RegisterSuccess";
import VerifyPhone from "../../components/Login/VerifyPhone/VerifyPhone";
import TwoFAPanel from "../../components/Login/TwoFAPanel/TwoFAPanel";
import LoginPanel from "../../components/Login/LoginPanel/LoginPanel";
import RegisterPanel from "../../components/Login/RegisterPanel/RegisterPanel";
import { Route, Routes, useLocation } from "react-router-dom";
import ResetPassword from "../../components/Login/ResetPassword/ResetPassword";
import { useTheme } from "../../context/ThemeContext";
import VerifyEmailHash from "../../components/Login/VerifyEmailHash/VerifyEmailHash";
import ChangePassword from "../../components/Login/ChangePassword/ChangePassword";

const LoginLeft = ({ noText = false }) => {
  const { isDarkMode } = useTheme();
  const [isTallScreen, setIsTallScreen] = useState(window.innerHeight > 1015);

  useEffect(() => {
    const handleResize = () => {
      setIsTallScreen(window.innerHeight > 1015);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const breakpoint2 = useMediaQuery("(max-width: 1250px)");
  const hideText = useMediaQuery("(max-width: 1028px)");
  const minHeight = useMediaQuery("(max-width: 768px)");

  if (minHeight) return;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: breakpoint2 ? "flex-start" : "center",
        maxWidth: "640px",
        padding: hideText ? "0 25px" : breakpoint2 ? "0 46px" : "0 70px",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        background: isDarkMode ? "rgba(73, 86, 112, 1)" : "none",
        width: hideText ? "33%" : breakpoint2 ? "35%" : "100%",
      }}
    >
      <Box
        sx={{
          margin: "0 auto",
          width: breakpoint2 ? "100%" : "500px",
          mt: hideText
            ? "152px"
            : breakpoint2
            ? "221px"
            : isTallScreen
            ? 0
            : "100px",
        }}
      >
        <FloatingMan
          width="auto"
          heightL="auto"
          style={{
            maxHeight: "55vh",
            width: "100%",
          }}
        />
      </Box>
      {!noText && !hideText && (
        <Box
          sx={{
            position: !isTallScreen ? "relative" : "absolute",
            bottom: !isTallScreen ? "unset" : "44px",
          }}
        >
          <Typography
            sx={{
              margin: "0 auto",
              maxWidth: breakpoint2 ? "80%" : "455px",
              color: isDarkMode ? "#FFF" : "rgba(0, 0, 0, 0.60)",
              textAlign: "center",
              fontWeight: 300,
              lineHeight: "175%",
            }}
          >
            Olivs is a business management software. Our Accounting, Payroll,
            Invoicing and Time & Attendance solutions help businesses with their
            daily and planning tasks.
          </Typography>
          <Box
            sx={{
              zIndex: 3333,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "18px",
              mb: "18px",
            }}
          >
            <a
              href="https://www.olivs.com"
              target="blank"
              className="link"
              style={{
                color: isDarkMode ? "#AAD269" : "#849F23",
                zIndex: 3333,
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 700,
                letterSpacing: "0.4px",
                textTransform: "capitalize",
                textDecoration: "none",
              }}
            >
              Visit Our Website
            </a>
            <Typography
              sx={{
                color: "#818595",
                fontSize: "16px",
                fontWeight: 400,
                ml: 2,
                mr: 2,
              }}
            >
              or
            </Typography>
            <a
              className="link"
              target="blank"
              href="https://www.linkedin.com/company/olivs"
            >
              <Linkedin style={{ float: "left" }} />
            </a>
          </Box>
        </Box>
      )}
      {!isDarkMode && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            "svg > rect": {
              width: "100%",
            },
          }}
        >
          <FloatingManBackground
            style={{
              maxHeight: "100%",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const Login = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const splitUrl = location?.pathname.split("/");
  const isRegister =
    splitUrl[splitUrl.length - 2] === "auth" &&
    splitUrl[splitUrl.length - 1] === "register";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "stretch",
        minHeight: "100vh",
        background: isDarkMode ? "#191F2C" : "none",
      }}
    >
      <LoginLeft noText={isRegister} />
      <Routes>
        <Route path={`*`} element={<LoginPanel />} />
        <Route path={`/register`} element={<RegisterPanel />} />
        <Route path={`/reset-password`} element={<ResetPassword />} />
        <Route path={`/verify-email`} element={<RegisterSuccessPanel />} />
        <Route path={`/verify-phone`} element={<VerifyPhone />} />
        <Route path={`/two-fa`} element={<TwoFAPanel />} />
        <Route path={`/0/en-au/olivs/verify`} element={<VerifyEmailHash />} />
        <Route
          path={`/0/en-au/olivs/forgotuserpassword`}
          element={<ChangePassword />}
        />
      </Routes>
    </Box>
  );
};

export default Login;
